import {
  Route,
  Warehouse,
  Delivery,
  RouteImportState,
  PickUp,
  Redelivery,
  GetOrders,
  GetOrderNode,
} from 'src/graphql/types/delivery';
import { GDeliveryStatusDmp } from 'src/graphql/types/generated';

export enum PrimaryCard {
  RouteListCard = 'RouteListCard',
  RouteDetailsCard = 'RouteDetailsCard',
  StopDetailsCard = 'StopDetailsCard',
  OrderDetailsCard = 'OrderDetailsCard',
  CohortListCard = 'CohortListCard',
  CohortDetailsCard = 'CohortDetailsCard',
}

export enum SecondaryCard {
  RouteDetailsEditDriver = 'RouteDetailsEditDriver',
  RouteDetailsEditOrigin = 'RouteDetailsEditOrigin',
  RouteDetailsChangeRoute = 'RouteDetailsChangeRoute',
  StopPopoverChangeRoute = 'StopPopoverChangeRoute',
  RouteListEditRouteImport = 'RouteListEditRouteImport',
  StopDetailsEditDeliveredQuantity = 'StopDetailsEditDeliveredQuantity',
}

export type RoutePrimaryStatusState = {
  routeId: string;
  totalStops: number;
  totalStopsVisited: number;
  totalUndeliveredStops: number;
  totalPartiallyDeliveredStops: number;
  totalNotPickedUpStops: number;
  totalPartiallyPickedUpStops: number;
};

export type StopPrimaryStatusState = {
  routeId: string;
  stopId: string;
  stopStatus: GDeliveryStatusDmp;
};

export type RouteStatusState = {
  routeId: string;
  totalStops: number;
  totalStopsVisited: number;
  hasSecondaryStatuses: boolean;
  totalUndeliveredStops: number;
  totalPartiallyDeliveredStops: number;
  totalNotPickedUpStops: number;
  totalPartiallyPickedUpStops: number;
  totalGoldMembers: number;
  totalSilverMembers: number;
  totalDoorDashStops: number;
  totalRefrigerationRequired: number;
  totalFreezerRequired: number;
  totalBienCuitStops: number;
  totalPastryStops: number;
};

export type StopSecondaryStatus = {
  hasRepeatedIssues: boolean;
  hasIssues: boolean;
  hasIssueNotes: boolean;
  hasDeliveryNote: boolean;
  hasWarehouseNote: boolean;
  hasReminder: boolean;
  isOutsideDeliveryWindow: boolean;
  isFirstDelivery: boolean;
  isRefrigerationRequired: boolean;
  isFreezerRequired: boolean;
  hasBienCuitOrder: boolean;
  hasPastryOrder: boolean;
};

export type StopStatusState = {
  routeId: string;
  stopId: string;
  stopStatus: GDeliveryStatusDmp;
  hasPastryOrders: boolean;
  stopSecondaryStatus: StopSecondaryStatus;
};

export type RoutingLocation = {
  locationId: string;
  value: boolean;
};

export type DeliveredQuantityEditor = {
  orderId?: string;
  orderLineId?: string;
  quantityDelivered?: number;
  itemName?: string;
  orderUnitQuantity?: number;
};

export enum RouteListTab {
  OdekoDeliveries = 'odeko-deliveries',
  ThirdPartyDeliveries = 'third-party-deliveries',
}

export type DeliveryState = {
  warehouses?: Warehouse[];
  warehousesLoading: boolean;
  routes?: Route[];
  routesLoading: boolean;
  selectedWarehouseId?: string;
  primaryCard: {
    activeCard: PrimaryCard;
    contentVisible?: boolean;
  };
  secondaryCardVisible?: SecondaryCard;
  popover: {
    visible: boolean;
    stop?: Delivery | PickUp | Redelivery;
    order?: GetOrderNode;
  };
  nightEnd: {
    current?: string;
    viewing?: string;
  };
  selectedRouteId: {
    id: string | null;
    hover?: boolean;
  };
  selectedCohortId: {
    id: string | null;
    hover?: boolean;
  };
  routeDetailsEditor: {
    isSaving: boolean;
    driverName?: string;
  };
  changeRouteEditor: {
    selectedStop?: Delivery | Redelivery | null;
    isSaving: boolean;
  };
  deliveryStopsReorderLoading: boolean;
  stopDetails: {
    selectedStop?: Delivery | PickUp | Redelivery;
  };
  createWarehouseDeliveryRoute: {
    isSaving: boolean;
  };
  routeImport: RouteImportState;
  deliveryPrimaryStatuses: {
    routeStatuses: RoutePrimaryStatusState[];
    stopStatuses: StopPrimaryStatusState[];
    redeliveryStopStatuses: StopPrimaryStatusState[];
    loading: boolean;
  };
  deliveryStatuses: {
    routeStatuses: RouteStatusState[];
    stopStatuses: StopStatusState[];
    redeliveryStopStatuses: StopStatusState[];
    loading: boolean;
  };
  routingLocations: RoutingLocation[];
  deliveredQuantityEditor: DeliveredQuantityEditor;
  isRoutesSyncEnabled: boolean;
  selectedOrderId?: string;
  selectedRouteListTab: RouteListTab;
  thirdPartyOrders?: GetOrders;
  thirdPartyOrdersLoading: boolean;
  isCohortView: boolean;
};

export const initialState: DeliveryState = {
  warehouses: undefined,
  warehousesLoading: false,
  routes: undefined,
  routesLoading: false,
  selectedWarehouseId: undefined,
  primaryCard: {
    activeCard: PrimaryCard.RouteListCard,
    contentVisible: undefined,
  },
  secondaryCardVisible: undefined,
  popover: {
    visible: false,
  },
  nightEnd: {
    current: undefined,
    viewing: undefined,
  },
  selectedRouteId: {
    id: null,
    hover: false,
  },
  selectedCohortId: {
    id: null,
    hover: false,
  },
  routeDetailsEditor: {
    isSaving: false,
    driverName: undefined,
  },
  changeRouteEditor: {
    selectedStop: undefined,
    isSaving: false,
  },
  deliveryStopsReorderLoading: false,
  stopDetails: {
    selectedStop: undefined,
  },
  createWarehouseDeliveryRoute: {
    isSaving: false,
  },
  routeImport: {
    isError: false,
    isLoading: false,
    isRunning: false,
    isUpdateTruckMutationLoading: false,
    plan: undefined,
    isPastWarehouseCutOffTime: false,
  },
  deliveryPrimaryStatuses: {
    routeStatuses: [],
    stopStatuses: [],
    redeliveryStopStatuses: [],
    loading: false,
  },
  deliveryStatuses: {
    routeStatuses: [],
    stopStatuses: [],
    redeliveryStopStatuses: [],
    loading: false,
  },
  routingLocations: [],
  deliveredQuantityEditor: {
    orderId: undefined,
    orderLineId: undefined,
    quantityDelivered: undefined,
    itemName: undefined,
    orderUnitQuantity: undefined,
  },
  isRoutesSyncEnabled: false,
  selectedOrderId: undefined,
  selectedRouteListTab: RouteListTab.OdekoDeliveries,
  thirdPartyOrders: undefined,
  thirdPartyOrdersLoading: false,
  isCohortView: false,
};
