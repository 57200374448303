import { ReactNode, StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'theme-ui';
import { Auth0Provider, AppState } from '@auth0/auth0-react';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import { theme } from './theme';
import App from './App';
import store from './state/store';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { Global, css } from '@emotion/react';
import { fontFace } from './fonts/fonts';
import config from './config';
import { DoppioProvider } from '@odekoteam/doppio';
import { initLDProvider } from './services/launchDarklyService';

let persistor = persistStore(store);

const rootEl = document.getElementById('root');
const AUTH0_DOMAIN = config().auth0_domain;
const AUTH0_CLIENT_ID = config().auth0_client_id;
const GATEWAY_API_URL = config().gateway_api_url;

const Auth0ProviderWithRedirectCallback = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState?: AppState): void => {
    navigate(appState?.returnTo || window.location.pathname);
  };
  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: GATEWAY_API_URL,
      }}
      useRefreshTokens
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

const renderApp = async (): Promise<void> => {
  const LDProvider = await initLDProvider();
  const root = createRoot(rootEl!);

  const doppioParams = { applicationName: 'logistics-web-app' };
  root.render(
    <StrictMode>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <Provider store={store}>
        <DoppioProvider {...doppioParams}>
          <BrowserRouter>
            <ThemeProvider theme={theme}>
              <Global
                styles={css`
                  ${fontFace}
                  @media print {
                    #packing-table {
                      padding: 30px;
                    }
                  }
                `}
              />
              <Auth0ProviderWithRedirectCallback>
                <PersistGate
                  loading={<div>Loading...</div>}
                  persistor={persistor}
                >
                  <LDProvider>
                    <App />
                  </LDProvider>
                </PersistGate>
              </Auth0ProviderWithRedirectCallback>
            </ThemeProvider>
          </BrowserRouter>
        </DoppioProvider>
      </Provider>
    </StrictMode>,
  );
};

renderApp();
