import { createSelector } from 'reselect';
import { RootState } from '../store';
import { DeliveryState } from '../slices/delivery';
import { isDateBeforeCurrentWarehouseNightEnd } from 'src/utils/date';
import { RouteImportState } from 'src/graphql/types/delivery';

export const deliverySelector: (state: RootState) => DeliveryState = (
  state: RootState,
) => state.delivery;

export const warehousesSelector = createSelector(
  deliverySelector,
  (delivery) => {
    return delivery.warehouses;
  },
);

export const warehousesLoadingSelector = createSelector(
  deliverySelector,
  (delivery) => {
    return delivery.warehousesLoading;
  },
);

export const routesSelector = createSelector(deliverySelector, (delivery) => {
  return delivery.routes;
});

export const nightEndSelector = createSelector(deliverySelector, (delivery) => {
  return delivery.nightEnd;
});

export const routesLoadingSelector = createSelector(
  deliverySelector,
  (delivery) => {
    return delivery.routesLoading;
  },
);

export const selectedWarehouseIdSelector = createSelector(
  deliverySelector,
  (delivery) => {
    return delivery.selectedWarehouseId;
  },
);

export const selectedRouteIdSelector = createSelector(
  deliverySelector,
  (delivery) => {
    return delivery.selectedRouteId;
  },
);

export const selectedCohortIdSelector = createSelector(
  deliverySelector,
  (delivery) => {
    return delivery.selectedCohortId;
  },
);

export const stopDetailsSelector = createSelector(
  deliverySelector,
  (delivery) => {
    return delivery.stopDetails;
  },
);

export const routeDetailsEditorSelector = createSelector(
  deliverySelector,
  (delivery) => {
    return delivery.routeDetailsEditor;
  },
);

export const deliveryStopsReorderLoadingSelector = createSelector(
  deliverySelector,
  (delivery) => {
    return delivery.deliveryStopsReorderLoading;
  },
);

export const changeRouteEditorSelector = createSelector(
  deliverySelector,
  (delivery) => {
    return delivery.changeRouteEditor;
  },
);

export const selectedWarehouseSelector = createSelector(
  deliverySelector,
  (delivery) => {
    if (!delivery.selectedWarehouseId) {
      return undefined;
    }
    return delivery.warehouses?.find(
      (warehouse) => warehouse.id === delivery.selectedWarehouseId,
    );
  },
);

export const isNightEndCurrentSelector = createSelector(
  deliverySelector,
  (delivery) => {
    return delivery.nightEnd.viewing === delivery.nightEnd.current;
  },
);

export const primaryCardSelector = createSelector(
  deliverySelector,
  (delivery) => {
    return delivery.primaryCard;
  },
);

export const secondaryCardVisibleSelector = createSelector(
  deliverySelector,
  (delivery) => {
    return delivery.secondaryCardVisible;
  },
);

export const routeImportSelector = createSelector(
  deliverySelector,
  (delivery): RouteImportState => {
    return delivery.routeImport;
  },
);

export const isSelectedNightEndBeforeWarehouseNightEnd = createSelector(
  deliverySelector,
  (delivery) => {
    const selectedDeliveryDay = delivery.nightEnd.viewing;
    if (selectedDeliveryDay === undefined) return false;
    return isDateBeforeCurrentWarehouseNightEnd(
      delivery,
      new Date(selectedDeliveryDay),
    );
  },
);

export const popoverSelector = createSelector(deliverySelector, (delivery) => {
  return delivery.popover;
});

export const deliveryStatusesSelector = createSelector(
  deliverySelector,
  (delivery) => {
    return delivery.deliveryStatuses;
  },
);

export const deliveryPrimaryStatusesSelector = createSelector(
  deliverySelector,
  (delivery) => {
    return delivery.deliveryPrimaryStatuses;
  },
);

export const deliveredQuantityEditorSelector = createSelector(
  deliverySelector,
  (delivery) => {
    return delivery.deliveredQuantityEditor;
  },
);

export const isRoutesSyncEnabled = createSelector(
  deliverySelector,
  (delivery) => {
    return delivery.isRoutesSyncEnabled;
  },
);

export const selectedOrderIdSelector = createSelector(
  deliverySelector,
  (delivery) => {
    return delivery.selectedOrderId;
  },
);

export const selectedRouteListTabSelector = createSelector(
  deliverySelector,
  (delivery) => {
    return delivery.selectedRouteListTab;
  },
);

export const thirdPartyOrdersSelector = createSelector(
  deliverySelector,
  (delivery) => {
    return delivery.thirdPartyOrders;
  },
);

export const isCohortViewSelector = createSelector(
  deliverySelector,
  (delivery) => {
    return delivery.isCohortView;
  },
);
