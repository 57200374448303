import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { Button, Spacer, Switch } from '@odekoteam/doppio';
import CancelButton from '../CancelButton';
import { Flex } from 'theme-ui';
import { useEffect, useState } from 'react';
import { modalSelector } from 'src/state/selectors/modal';
import { GetLocation, GetLocationCohort } from 'src/graphql/types/delivery';
import { GUpdateLocationsIncludeInAutomatedRoutingMutation } from 'src/graphql/types/generated';
import { useMutation } from 'urql';
import { UPDATE_LOCATIONS_INCLUDE_IN_AUTOMATED_ROUTING } from 'src/graphql/requests/updateLocationsIncludeInAutomatedRouting';
import { hideModal } from 'src/state/slices/modal';
import { LoaderSpinner } from '../../Loader';
import { updateRoutingLocations } from 'src/state/slices/locations';
import ExpandableLocationsList from './ExpandableLocationsList';

const ERROR_MSG =
  'Error: one or more of the locations you have selected does not belong to an enabled cohort and cannot be enabled for automated routing. Please add a cohort to that location before enabling automated routing.';

const AutomatedRoutingTab = (): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const {
    variables: { locations, cohorts },
  } = useAppSelector(modalSelector);
  const [includeInAutomatedRouting, setIncludeInAutomatedRouting] =
    useState<boolean>(true);
  const getLabel = (): string => (includeInAutomatedRouting ? 'ON' : 'OFF');
  const [
    updateLocationsIncludeInAutomatedRoutingResult,
    updateLocationsIncludeInAutomatedRouting,
  ] = useMutation<GUpdateLocationsIncludeInAutomatedRoutingMutation>(
    UPDATE_LOCATIONS_INCLUDE_IN_AUTOMATED_ROUTING,
  );
  const noRoutingCohort = locations.some(
    (location: GetLocation) =>
      !location.routingCohort ||
      cohorts.findIndex(
        (cohort: GetLocationCohort) => location.routingCohort?.id === cohort.id,
      ) === -1,
  );
  const isError = noRoutingCohort && includeInAutomatedRouting;

  const handleAction = (): void => {
    const locationIds = locations.map(
      (location: GetLocation): string => location.id,
    );
    updateLocationsIncludeInAutomatedRouting({
      input: {
        locationIds,
        includeInAutomatedRouting,
      },
    });
  };

  useEffect(() => {
    if (updateLocationsIncludeInAutomatedRoutingResult.data) {
      const routingLocations = locations.map((location: GetLocation) => ({
        locationId: location.id,
        value: includeInAutomatedRouting,
      }));
      dispatch(updateRoutingLocations(routingLocations));
      dispatch(hideModal());
    }
  }, [updateLocationsIncludeInAutomatedRoutingResult]);

  return (
    <>
      <Flex sx={{ alignItems: 'center', marginBottom: '10px' }}>
        <span sx={{ variant: 'text.paragraphMdDefault' }}>
          Update automated routing to:
        </span>
        <div sx={{ marginX: '8px' }}>
          <Switch
            id="automated-routing-switch-bulk"
            accessibilityLabel="automated routing toggle"
            onCheckedChange={(): void =>
              setIncludeInAutomatedRouting(!includeInAutomatedRouting)
            }
            size="default"
            isChecked={includeInAutomatedRouting}
          />
        </div>
        <span sx={{ variant: 'text.paragraphLgDefault' }}>{getLabel()}</span>
      </Flex>
      <div sx={{ variant: 'text.paragraphMdDefault', marginBottom: '20px' }}>
        You will be updating {locations.length} locations. Would you like to
        turn automated routing{' '}
        <b sx={{ variant: 'text.labelMd' }}>{getLabel()}</b> for these{' '}
        {locations.length} locations?
      </div>
      <ExpandableLocationsList locations={locations} />
      {isError && (
        <div sx={{ variant: 'text.labelLg', marginTop: '20px' }}>
          {ERROR_MSG}
        </div>
      )}
      <Flex sx={{ justifyContent: 'center', marginTop: '24px' }}>
        {updateLocationsIncludeInAutomatedRoutingResult.fetching && (
          <LoaderSpinner
            imgStyle={{
              marginLeft: '-200px',
              bottom: '32px',
              height: '20px',
              top: 'auto',
              left: 'auto',
            }}
          />
        )}
        <Button
          id="modal-update-automated-routing-action-button"
          variant="secondary"
          onPress={handleAction}
          isDisabled={
            updateLocationsIncludeInAutomatedRoutingResult.fetching || isError
          }
        >
          Update
        </Button>
        <Spacer width="$1" />
        <CancelButton />
      </Flex>
    </>
  );
};
export default AutomatedRoutingTab;
