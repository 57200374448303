import { TableCell } from 'src/components/Table';
import { GetLocation, GetLocationCohort } from 'src/graphql/types/delivery';
import AutomatedRoutingSwitch from '../AutomatedRoutingSwitch';
import config from 'src/config';
import MembershipBadge from '../MembershipBadge';
import { Flex } from 'theme-ui';
import DoorDashIcon from '../DoorDashIcon';
import DeliveryWindow from '../DeliveryWindow';
import { Checkbox } from '@odekoteam/doppio';
import { isDefined } from 'src/utils';
import HighlightedText from '../HighlightedText';
import RoutingCohortTag from '../RoutingCohortTag';
import { useAppSelector } from 'src/hooks/store';
import { routingLocationsSelector } from 'src/state/selectors/locations';
import { useEffect, useState } from 'react';

const cellStyle = { borderBottom: '1px solid #E6E2DA' };

const LocationRow = ({
  location,
  searchValue,
  setSelectedLocations,
  selectedLocations,
  cohorts,
}: {
  location: GetLocation;
  searchValue: string;
  selectedLocations: GetLocation[];
  setSelectedLocations: React.Dispatch<React.SetStateAction<GetLocation[]>>;
  cohorts?: GetLocationCohort[];
}): JSX.Element => {
  const soApiUrl = `${config().so_api_url}/manage/locations/${location.keyId}`;
  const isDoorDash = location.isDoordash;
  const selectedLocationIndex = selectedLocations.findIndex(
    (selectedLocation) => selectedLocation.id === location.id,
  );
  const isSelected = selectedLocationIndex !== -1;
  const routingLocations = useAppSelector(routingLocationsSelector);

  const locationInState = routingLocations.find(
    (routingLocation) => routingLocation.locationId === location.id,
  );
  const locationCohort = locationInState?.routingCohort
    ? locationInState.routingCohort
    : location.routingCohort;

  return (
    <>
      <TableCell
        data-test-id={`location-checkbox-cell-${location.id}`}
        style={cellStyle}
      >
        <Checkbox
          isChecked={isSelected}
          isDisabled={!isDefined(location.includeInAutomatedRouting)}
          onChange={(value): void => {
            let newSelectedLocations = [];
            if (value) {
              newSelectedLocations = [...selectedLocations, location];
            } else {
              newSelectedLocations = selectedLocations.filter(
                (selectedLocation) => selectedLocation.id !== location.id,
              );
            }
            setSelectedLocations(newSelectedLocations);
          }}
        />
      </TableCell>
      <TableCell
        data-test-id={`location-so-id-cell-${location.id}`}
        style={cellStyle}
      >
        <a href={soApiUrl} target="_blank" rel="noopener noreferrer">
          <HighlightedText text={location.keyId} highlight={searchValue} />
        </a>
      </TableCell>
      <TableCell
        data-test-id={`location-name-cell-${location.id}`}
        style={cellStyle}
      >
        <Flex>
          <MembershipBadge
            membershipTier={location.membershipTier}
            style={{ marginRight: '8px' }}
            testId={location.id}
          />
          {isDoorDash && (
            <DoorDashIcon style={{ marginRight: '8px' }} testId={location.id} />
          )}
          <HighlightedText
            text={location.businessName}
            highlight={searchValue}
          />
        </Flex>
      </TableCell>
      <TableCell
        data-test-id={`location-delivery-window-cell-${location.id}`}
        style={cellStyle}
      >
        <DeliveryWindow
          locationDeliveryWindow={location?.locationDeliveryWindow}
          id={location.id}
          showIcon={false}
        />
      </TableCell>
      <TableCell
        data-test-id={`location-city-cell-${location.id}`}
        style={cellStyle}
      >
        {location.address.city}
      </TableCell>
      <TableCell
        data-test-id={`location-state-cell-${location.id}`}
        style={cellStyle}
      >
        {location.address.state}
      </TableCell>
      <TableCell
        data-test-id={`location-postalCode-cell-${location.id}`}
        style={cellStyle}
      >
        {location.address.postalCode}
      </TableCell>
      <TableCell
        data-test-id={`location-cohort-cell-${location.id}`}
        style={cellStyle}
      >
        <RoutingCohortTag routingCohort={locationCohort} />
      </TableCell>
      <TableCell
        data-test-id={`location-automated-routing-cell-${location.id}`}
        style={cellStyle}
      >
        <AutomatedRoutingSwitch
          locationId={location.id}
          value={location.includeInAutomatedRouting}
          routingCohort={location.routingCohort}
          cohorts={cohorts}
        />
      </TableCell>
    </>
  );
};

export default LocationRow;
