import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type RoutingLocation = {
  locationId: string;
  value: boolean;
  routingCohort?: {
    id: string;
    color?: string;
    name: string;
  };
};

export type LocationState = {
  routingLocations: RoutingLocation[];
};

const initialState: LocationState = {
  routingLocations: [],
};

const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    updateRoutingLocations: (
      state: LocationState,
      action: PayloadAction<RoutingLocation[]>,
    ): LocationState => {
      const myLocations = [...state.routingLocations];
      action.payload.forEach((routingLocation) => {
        const foundLocationIndex = myLocations.findIndex(
          (location: RoutingLocation): boolean =>
            location.locationId === routingLocation.locationId,
        );
        if (foundLocationIndex === -1) myLocations.push(routingLocation);
        else
          myLocations[foundLocationIndex] = {
            ...myLocations[foundLocationIndex],
            ...routingLocation,
          };
      });
      return { ...state, routingLocations: myLocations };
    },
  },
});

const { reducer, actions } = locationsSlice;
export const { updateRoutingLocations } = actions;
export default reducer;
