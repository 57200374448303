import { ReactNode, useEffect, useState } from 'react';
import Transition from 'src/components/Animations/Transition';
import CompletionIndicator from 'src/components/CompletionIndicator';
import RotatingArrow from 'src/components/RotatingArrow';
import { Flex, ThemeUICSSObject } from 'theme-ui';
import CutoffTagOrder from '../CutoffTag/CutoffTagOrder';
import { CustomTagType } from '../CutoffTag/CutoffTagStop';
import Tag, { TagIcons } from '../Tag';

const expandableHeaderArrow: ThemeUICSSObject = {
  width: '100%',
  '&:hover': {
    cursor: 'pointer',
  },
};

const expandableContent: ThemeUICSSObject = {
  padding: '10px 0px 0px 38px',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
};

const expandableHeaderTitle: ThemeUICSSObject = {
  fontFamily: 'heading',
  fontSize: 2,
  color: 'neutralText',
  marginLeft: '15px',
  marginRight: '10px',
};

const Expandable = ({
  title,
  expanded,
  children,
  completed = false,
  id,
  showCompletedIndicator = true,
  dueAt,
  isWarehouse,
  cutOffTagVisible = false,
  isOrderDetails,
}: {
  title: string;
  expanded?: boolean;
  children: ReactNode;
  completed?: boolean;
  id?: string;
  dueAt?: string;
  isWarehouse?: boolean;
  showCompletedIndicator?: boolean;
  cutOffTagVisible?: boolean;
  isOrderDetails?: boolean;
}): JSX.Element => {
  const [isExpanded, setExpanded] = useState(expanded);
  const [height, setHeight] = useState<number>(0);

  const expandableContainerStyle = !showCompletedIndicator
    ? {
        marginBottom: '0px',
        border: '1px solid',
        borderRadius: '8px',
        borderColor: 'backgroundMedium',
        padding: '8px',
      }
    : { marginBottom: '22px' };
  const titleStyle = !showCompletedIndicator
    ? {
        ...expandableHeaderTitle,
        marginLeft: '0px',
        fontFamily: 'label',
        variant: 'text.labelMd',
      }
    : { ...expandableHeaderTitle };
  const expandableContentStyle = !showCompletedIndicator
    ? {
        ...expandableContent,
        paddingLeft: '0px',
        paddingRight: '0px',
        gap: '0px',
      }
    : { ...expandableContent };
  const expandableHeaderArrowStyle = !showCompletedIndicator
    ? { marginLeft: '8px', marginTop: 'auto' }
    : { marginLeft: '8px' };

  useEffect(() => {
    const renderedHeight =
      document.getElementById(`expandableChildren_${id}`)?.clientHeight ?? 0;

    setHeight(renderedHeight);
  }, [id]);

  const toggleExpanded = (): void => {
    setExpanded(isExpanded === false);
  };

  return (
    <div id={id} data-test-id={id} sx={expandableContainerStyle}>
      <Flex sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Flex>
          {showCompletedIndicator && (
            <CompletionIndicator
              completed={completed}
              id={`${id}-${completed ? 'complete' : 'incomplete'}`}
            />
          )}
          <Flex
            sx={expandableHeaderArrow}
            onClick={toggleExpanded}
            data-test-id="select-routes-expand-collapse"
          >
            <span sx={titleStyle}>{title}</span>
            {cutOffTagVisible && (
              <CutoffTagOrder
                dueAt={dueAt}
                isWarehouse={isWarehouse ?? false}
                tagType={CustomTagType.full}
              />
            )}
            <span sx={expandableHeaderArrowStyle}>
              <RotatingArrow id="expandable-arrow" isRotated={isExpanded} />
            </span>
          </Flex>
        </Flex>
        {isOrderDetails && (
          <Tag
            text="Sent to External Partner"
            icon={TagIcons.externalPartner}
            variant="labelMd"
            color="secondaryText"
          />
        )}
      </Flex>
      <Transition
        toggleAnimation={isExpanded}
        height={{ expanded: height, collapsed: 0 }}
      >
        <div id={`expandableChildren_${id}`} sx={expandableContentStyle}>
          {children}
        </div>
      </Transition>
    </div>
  );
};

export default Expandable;
