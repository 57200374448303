import { gql } from 'urql';
import { PRODUCT } from '../client';

export const UPDATE_LOCATIONS_ROUTING_COHORT = gql`
  mutation ${PRODUCT}UpdateLocationsRoutingCohort($input: UpdateLocationsRoutingCohortMutationInput!) {
    updateLocationsRoutingCohort(input: $input) {
      success
    }
  }
`;
