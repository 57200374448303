import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/state/store';
import { getModifiedIsoDate } from 'src/utils/date';
import { getSelectedWarehouse } from 'src/utils';
import { getRoutes } from './getRoutes';
import { DeliveryState } from './state';
import { setRouteListCardVisible } from '.';
import { getThirdPartyOrders } from './getThirdPartyOrders';

export const setSelectedWarehouseId = createAsyncThunk(
  'delivery/setSelectedWarehouseId',
  async (
    { warehouseId }: { warehouseId: string },
    thunkAPI,
  ): Promise<{ warehouseId: string; nightEnd: string }> => {
    const rootState: RootState = thunkAPI.getState() as RootState;
    const nightEndState = rootState.delivery.nightEnd;
    const selectedWarehouse = getSelectedWarehouse(
      warehouseId,
      rootState.delivery.warehouses,
    );
    const nightEnd =
      nightEndState.current === nightEndState.viewing
        ? getModifiedIsoDate(selectedWarehouse?.currentNightEnd)
        : nightEndState.viewing;
    thunkAPI.dispatch(getRoutes({ warehouseId, nightEnd: nightEnd ?? '' }));
    thunkAPI.dispatch(
      getThirdPartyOrders({ warehouseId, nightEnd: nightEnd ?? '' }),
    );
    thunkAPI.dispatch(setRouteListCardVisible());
    return { warehouseId, nightEnd: nightEnd ?? '' };
  },
);

export const setSelectedWarehouseIdFulfilled = (
  state: DeliveryState,
  action: PayloadAction<{ warehouseId: string; nightEnd: string }>,
): void => {
  const selectedWarehouse = getSelectedWarehouse(
    action.payload.warehouseId,
    state.warehouses,
  );
  state.selectedWarehouseId = action.payload.warehouseId;
  const roundedDate = getModifiedIsoDate(selectedWarehouse?.currentNightEnd);
  state.nightEnd.current = roundedDate;
  state.nightEnd.viewing = action.payload.nightEnd;
  state.routeImport.plan = undefined;
  // this messes up cypress tests so we skip in testing
  if (process.env.CYPRESS_AUTH_MOCKED !== 'true') {
    state.deliveryStatuses = {
      routeStatuses: [],
      stopStatuses: [],
      redeliveryStopStatuses: [],
      loading: false,
    };
  }
};
