import Tag from '../Tag';
import { selectedWarehouseSelector } from 'src/state/selectors/delivery';
import { useAppSelector } from 'src/hooks/store';
import { format, utcToZonedTime } from 'date-fns-tz';
import { Tooltip } from '@odekoteam/doppio';

const CUTOFF_TIME_TEXT = 'Odeko Warehouse Cutoff Time';

export enum CustomTagType {
  truncated = 'truncated',
  full = 'full',
}

const CutoffTagOrder = ({
  tagType = CustomTagType.truncated,
  dueAt = '',
  isWarehouse,
  width,
}: {
  tagType?: CustomTagType;
  dueAt?: string;
  isWarehouse: boolean;
  width?: string;
}): JSX.Element | null => {
  const selectedWarehouse = useAppSelector(selectedWarehouseSelector);
  const timeZone = selectedWarehouse?.timeZone;

  if (!timeZone || dueAt === '') return null;

  const zonedTime = utcToZonedTime(dueAt, timeZone);
  const longTime = format(zonedTime, 'h:mm aa zzz', { timeZone });
  const shortTime = format(zonedTime, 'h:mm aa', { timeZone });

  if (!isWarehouse)
    return <Tag text="Vendor Order" style={{ maxWidth: '120px' }} />;

  const tagCopy = isWarehouse
    ? tagType === CustomTagType.truncated
      ? `${shortTime}`
      : `${longTime} Cutoff`
    : 'Vendor Order';

  return (
    <Tooltip labelText={CUTOFF_TIME_TEXT} placement="bottom">
      <span>
        <Tag
          text={tagCopy}
          style={{
            height: '30px',
            maxWidth: '120px',
            width: width ? width : 'fit-content',
          }}
        />
      </span>
    </Tooltip>
  );
};

export default CutoffTagOrder;
