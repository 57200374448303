import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { GET_ROUTING_IMPORT_PLAN_CUT_OFF } from 'src/graphql/requests/queries';
import { client } from 'src/graphql/client';
import {
  RouteImportPlan,
  RouteImportState,
  Tense,
  RoutingImportCohort,
} from 'src/graphql/types/delivery';
import { GGetRoutingImportPlanCutOffQuery } from 'src/graphql/types/generated';
import { DeliveryState } from './state';
import { getRubyFriendlyUtcStr, pastPresentFutureDate } from 'src/utils/date';
import { RootState } from 'src/state/store';
import { getRoutes } from './getRoutes';

type GetRouteImportInfoThunk = {
  isRunning: boolean;
  plan: RouteImportPlan;
  cohorts: RoutingImportCohort[];
  isPastWarehouseCutOffTime: boolean;
};

// have the values for isRunning or the truck count changed
const hasRouteImportFinished = (
  previousStateRouteImport: RouteImportState,
  currentStateRouteImport: GetRouteImportInfoThunk,
): boolean => {
  if (previousStateRouteImport.isRunning && !currentStateRouteImport.isRunning)
    return true;

  return false;
};

export const getRouteImportInfo = createAsyncThunk(
  'delivery/getRouteImportInfo',
  async (
    { viewingNightEnd }: { viewingNightEnd: string; isPolling?: boolean },
    thunkAPI,
  ): Promise<GetRouteImportInfoThunk | null> => {
    const { delivery }: RootState = thunkAPI.getState() as RootState;
    const myTense = pastPresentFutureDate(delivery, viewingNightEnd);
    const warehouseId = delivery.selectedWarehouseId;
    const previousStateRouteImport = { ...delivery.routeImport };
    const rubyFriendlyUtcStr = getRubyFriendlyUtcStr();
    if (!warehouseId || !viewingNightEnd) return null;
    const { data, error } = await client
      .query<GGetRoutingImportPlanCutOffQuery>(
        GET_ROUTING_IMPORT_PLAN_CUT_OFF,
        {
          routePlanInput: {
            warehouseId,
            nightEnd: viewingNightEnd,
          },
          cutOffInput: {
            dateTime: rubyFriendlyUtcStr,
            warehouseId,
          },
        },
        { requestPolicy: 'cache-and-network' },
      )
      .toPromise();

    if (
      error ||
      (!data?.routingImportPlan && !data?.isPastWarehouseCutOffTime)
    ) {
      // TODO: do something with the error
      console.error('No routing import info found');
      console.error(error);
      return null;
    }

    // IsPastWarehouseCutOffTime query does not work for future dates. Setting isPastWarehouseCutOffTime field to false if we are in the future.
    const currentStateRouteImport: GetRouteImportInfoThunk = {
      isRunning: data.routingImportPlan.status === 'in_progress',
      plan: {
        ...data.routingImportPlan,
        warehouseId,
      },
      cohorts: data.routingImportPlan?.cohorts ?? [],
      isPastWarehouseCutOffTime:
        myTense === Tense.future ? false : data.isPastWarehouseCutOffTime,
    };

    if (
      hasRouteImportFinished(previousStateRouteImport, currentStateRouteImport)
    ) {
      thunkAPI.dispatch(getRoutes({ warehouseId, nightEnd: viewingNightEnd }));
    }
    return currentStateRouteImport;
  },
);

export const getRouteImportInfoFulfilled = (
  state: DeliveryState,
  action: PayloadAction<GetRouteImportInfoThunk | null>,
): void => {
  if (action.payload) {
    state.routeImport = {
      ...state.routeImport,
      ...action.payload,
      isLoading: false,
      isError: false,
    };
  } else {
    // if no data is returned, reset values
    state.routeImport = {
      ...state.routeImport,
      isRunning: false,
      plan: undefined,
      isPastWarehouseCutOffTime: false,
      isLoading: false,
      isError: true,
    };
  }
};

export const getRouteImportInfoPending = (
  state: DeliveryState,
  action: any,
): void => {
  const isPolling = action.meta.arg.isPolling;
  state.routeImport = {
    ...state.routeImport,
    isLoading: !isPolling,
    isError: false,
  };
};

export const getRouteImportInfoRejected = (state: DeliveryState): void => {
  state.routeImport = {
    ...state.routeImport,
    isLoading: false,
    isError: true,
  };
};
