import { Spacer, Tabs } from '@odekoteam/doppio';
import { Flex } from 'theme-ui';
import { messageStyle, styledConfirmationModal } from '../styles';
import AutomatedRoutingTab from './AutomatedRoutingTab';
import CohortsTab from './CohortsTab';

enum BulkEditTab {
  Cohorts = 'Cohorts',
  AutomatedRouting = 'AutomatedRouting',
}
const BulkEditLocationsModal = (): JSX.Element | null => {
  return (
    <div
      id="automated-routing-modal"
      sx={{ ...styledConfirmationModal, maxWidth: '800px' }}
    >
      <Flex sx={messageStyle}>Update locations</Flex>
      <Spacer height="$4" />
      <Tabs.Root defaultValue={BulkEditTab.Cohorts}>
        <Tabs.Item
          value={BulkEditTab.Cohorts}
          label="Cohorts"
          key={BulkEditTab.Cohorts}
          // TODO: add id prop to Doppio Tab
          // @ts-ignore
          nativeID={`tab-${BulkEditTab.Cohorts}`}
        >
          <div sx={{ marginTop: '24px' }}>
            <CohortsTab />
          </div>
        </Tabs.Item>
        <Tabs.Item
          value={BulkEditTab.AutomatedRouting}
          label="Automated Routing"
          key={BulkEditTab.AutomatedRouting}
          // TODO: add id prop to Doppio Tab
          // @ts-ignore
          nativeID={`tab-${BulkEditTab.AutomatedRouting}`}
        >
          <div sx={{ marginTop: '24px' }}>
            <AutomatedRoutingTab />
          </div>
        </Tabs.Item>
      </Tabs.Root>
    </div>
  );
};
export default BulkEditLocationsModal;
