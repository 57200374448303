import RoutingCohortTag from 'src/components/RoutingCohortTag';
import Expandable from 'src/components/StopDetailsCard/Expandable';
import { GetLocation } from 'src/graphql/types/delivery';
import { Flex } from 'theme-ui';

const ExpandableLocationsList = ({
  locations,
}: {
  locations: GetLocation[];
}): JSX.Element => {
  return (
    <Expandable
      title="Locations to update"
      id="locations"
      expanded={false}
      showCompletedIndicator={false}
    >
      <div sx={{ maxHeight: '30vh', overflow: 'auto' }}>
        {locations.map((location: GetLocation) => (
          <Flex
            key={location.id}
            sx={{
              variant: 'text.paragraphMdDefault',
              marginBottom: '4px',
              marginLeft: '4px',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <span>{location.businessName}</span>
            <RoutingCohortTag routingCohort={location.routingCohort} />
          </Flex>
        ))}
      </div>
    </Expandable>
  );
};

export default ExpandableLocationsList;
