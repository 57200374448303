import { RoutingCohort, RoutingImportCohort } from 'src/graphql/types/delivery';
import { Flex, ThemeUICSSObject } from 'theme-ui';

const RoutingCohortTag = ({
  id,
  routingCohort,
  count,
}: {
  id?: string;
  routingCohort?: RoutingCohort | RoutingImportCohort;
  count?: number;
}): JSX.Element | null => {
  return (
    <Flex
      data-test-id={`cohort-tag-${id}-${routingCohort?.id}`}
      sx={{ ...tagStyle, backgroundColor: routingCohort?.color }}
    >
      {routingCohort?.name}
      {count && <span sx={{ marginLeft: '4px' }}>{count}</span>}
    </Flex>
  );
};

const tagStyle: ThemeUICSSObject = {
  variant: 'text.paragraphMdDefault',
  borderRadius: '4px',
  padding: '4px',
  height: '30px',
  width: 'fit-content',
};
export default RoutingCohortTag;
