import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { Button, NativeSelect, Spacer } from '@odekoteam/doppio';
import CancelButton from '../CancelButton';
import { Flex } from 'theme-ui';
import { useEffect, useState } from 'react';
import { modalSelector } from 'src/state/selectors/modal';
import { GetLocation, GetLocationCohort } from 'src/graphql/types/delivery';
import { GUpdateLocationsRoutingCohortMutation } from 'src/graphql/types/generated';
import { useMutation } from 'urql';
import { hideModal } from 'src/state/slices/modal';
import { LoaderSpinner } from '../../Loader';
import {
  RoutingLocation,
  updateRoutingLocations,
} from 'src/state/slices/locations';
import ExpandableLocationsList from './ExpandableLocationsList';
import { UPDATE_LOCATIONS_ROUTING_COHORT } from 'src/graphql/requests/updateLocationsRoutingCohort';
import { Item } from 'src/components/LocationsTable/utils';

const CohortsTab = (): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const {
    variables: { locations, cohorts },
  } = useAppSelector(modalSelector);
  const cohortItems = cohorts?.map(
    (cohort: GetLocationCohort): Item => ({
      value: cohort.id,
      label: cohort.name,
    }),
  );
  const [cohortId, setCohortId] = useState<string>(cohortItems[0].value);
  const getSelectedCohort = (): GetLocationCohort =>
    cohorts.find((cohort: GetLocationCohort) => cohort.id === cohortId);

  const [updateLocationsRoutingCohortResult, updateLocationsRoutingCohort] =
    useMutation<GUpdateLocationsRoutingCohortMutation>(
      UPDATE_LOCATIONS_ROUTING_COHORT,
    );

  const handleAction = (): void => {
    const locationIds = locations.map(
      (location: GetLocation): string => location.id,
    );
    updateLocationsRoutingCohort({
      input: {
        locationIds,
        cohortId,
      },
    });
  };

  useEffect(() => {
    if (
      updateLocationsRoutingCohortResult.data?.updateLocationsRoutingCohort
        ?.success
    ) {
      const selectedCohort = getSelectedCohort();
      const routingLocations: RoutingLocation[] = locations.map(
        (location: GetLocation) => ({
          locationId: location.id,
          value: location.includeInAutomatedRouting,
          routingCohort: selectedCohort,
        }),
      );
      dispatch(updateRoutingLocations(routingLocations));
      dispatch(hideModal());
    }
  }, [updateLocationsRoutingCohortResult]);

  return (
    <>
      <Flex sx={{ alignItems: 'center', marginBottom: '10px' }}>
        <span sx={{ variant: 'text.paragraphMdDefault' }}>
          Update cohort to:
        </span>
        <div sx={{ marginX: '8px' }}>
          <NativeSelect
            id="cohort-input-controlled"
            items={cohortItems}
            value={cohortId}
            onValueChange={(value): void => setCohortId(value as string)}
          />
        </div>
      </Flex>
      <div sx={{ variant: 'text.paragraphMdDefault', marginBottom: '20px' }}>
        You will be updating {locations.length} locations. Would you like to
        update the cohort to{' '}
        <b sx={{ variant: 'text.labelMd' }}>{getSelectedCohort().name}</b> for
        these {locations.length} locations?
      </div>
      <ExpandableLocationsList locations={locations} />
      <Flex sx={{ justifyContent: 'center', marginTop: '24px' }}>
        {updateLocationsRoutingCohortResult.fetching && (
          <LoaderSpinner
            imgStyle={{
              marginLeft: '-200px',
              bottom: '32px',
              height: '20px',
              top: 'auto',
              left: 'auto',
            }}
          />
        )}
        <Button
          id="modal-update-automated-routing-action-button"
          variant="secondary"
          onPress={handleAction}
          disabled={updateLocationsRoutingCohortResult.fetching}
        >
          Update
        </Button>
        <Spacer width="$1" />
        <CancelButton />
      </Flex>
    </>
  );
};
export default CohortsTab;
