import { ThemeUICSSObject } from 'theme-ui';

export const stopDetailMapCard: ThemeUICSSObject = {
  width: ['100%', '540px'],
  display: 'flex',
  flexDirection: 'column',
  '.content-wrapper': {
    marginLeft: '32px',
  },
};

export const stopDetailsHeaderTitle: ThemeUICSSObject = {
  display: 'flex',
  flexDirection: 'row',
};

export const newLocationTagStyle: ThemeUICSSObject = {
  color: 'infoBackgroundHover',
  backgroundColor: 'infoBackgroundWeak',
  marginRight: '8px',
};

export const stopDetailsSubheader: ThemeUICSSObject = {
  marginTop: '6px',
  marginBottom: '20px',
  paddingRight: '14px',
};

export const stopDetailsSubheaderDeliveryWindow: ThemeUICSSObject = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
};

export const stopDetailsSubheaderDeliveryIssues: ThemeUICSSObject = {
  marginTop: '16px',
};

export const deliveryWindow: ThemeUICSSObject = {
  alignItems: 'center',
};

export const stopLabel: ThemeUICSSObject = {
  fontFamily: 'heading',
  fontSize: 3,
  display: 'flex',
  alignItems: 'center',
};

export const currentRouteAndStop: ThemeUICSSObject = {
  marginTop: '4px',
};

export const titleContainer: ThemeUICSSObject = {
  marginLeft: '8px',
  flexDirection: 'column',
};

export const driverReminderTitleContainer: ThemeUICSSObject = {
  margin: '4px 0px',
  zIndex: 1,
  position: 'relative',
};

export const driverReminderTitle: ThemeUICSSObject = {
  fontFamily: 'label',
  fontSize: 1,
};

export const driverReminderTitleIcon: ThemeUICSSObject = {
  marginX: '4px',
  cursor: 'pointer',
};

export const uploadedImagesTitle: ThemeUICSSObject = {
  fontFamily: 'label',
  fontSize: 2,
};

export const imageCountStyle: ThemeUICSSObject = {
  fontFamily: 'heading',
  variant: 'text.paragraphSmDefault',
};

export const imageCountContainer: ThemeUICSSObject = {
  display: 'flex',
  background: '#FFF5D6',
  width: '20px',
  height: '20px',
  borderRadius: '20px',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: '4px',
};

export const uploadedImagesTitleWrapper: ThemeUICSSObject = {
  display: 'flex',
  flexDirection: 'row',
  marginBottom: '4px',
};

export const noImagesLabelStyle: ThemeUICSSObject = {
  variant: 'text.paragraphMdDefault',
  fontFamily: 'body',
  color: 'secondaryText',
};

export const imageGalleryWrapper: ThemeUICSSObject = {
  marginBottom: '24px',
};

export const thumbnailStyle: ThemeUICSSObject = {
  width: '125px',
  height: '150px',
  borderRadius: '8px',
  objectFit: 'cover',
};

export const imageListStyle: ThemeUICSSObject = {
  overflow: 'auto',
  display: 'flex',
  marginTop: '12px',
  maxHeight: '450px',
  minWidth: '160px',
  gap: '12px',
};

export const loaderStyles: ThemeUICSSObject = {
  height: '150px',
  width: '125px',
  marginRight: '12px',
};

export const dropOffPickUpTab: ThemeUICSSObject = {
  maxHeight: ['calc(100vh - 598px)', 'calc(100vh - 526px)'],
  overflow: 'auto',
  display: 'flex',
  paddingRight: '14px',
  flexDirection: 'column',
  gap: '27px',
};

export const tabWrapper: ThemeUICSSObject = {
  maxHeight: ['calc(100vh - 710px)', 'calc(100vh - 640px)'],
  display: 'flex',
  paddingRight: '14px',
  flexDirection: 'column',
  gap: '27px',
};

export const showMoreStyle: ThemeUICSSObject = {
  marginTop: '12px',
  marginBottom: '6px',
};
